import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import HeroCarousel from "../components/heroCarousel"
import FeaturedFilms from "../components/FeaturedFilms"
// import Image from "../components/image"
import SEO from "../components/seo"

class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      vimeo: "",
      modalOpen: false
    }
    this.toggleTrailer = this.toggleTrailer.bind(this);
    this.showTrailer = this.showTrailer.bind(this);

  }
  showTrailer(vimeoId) {
    
    const trailer = "https://player.vimeo.com/video/"+ vimeoId +"?title=0&byline=0&portrait=0&autoplay=1"

    this.setState({ modalOpen: true, vimeo: trailer});

  }
  toggleTrailer() {
    const currentState = this.state.modalOpen;
    this.setState({ modalOpen: !currentState });
  }
  render() {
    const {vimeo, modalOpen} = this.state;
    const data = this.props.data.wordpressPage.acf;
    const newFilms = data.featured_products;
    return (
      <Layout>
        <SEO title="Visit Films - Quality American, Independent and World Cinema" />
        <div>
          <section className="section section__tag">
            <div className="container">
              <h3>a worldwide film sales company specializing in director driven work that premieres at the world’s top tier film festivals</h3>
            </div>
          </section>
          <HeroCarousel title={data.featured_titles_header_text} featuredTitles={data.featured_titles} showTrailer={this.showTrailer}/>
          <FeaturedFilms title={data.new_titles_header_text} films={newFilms}/>
          {modalOpen !== false ? 
          <div id="modal-container" className={this.state.modalOpen ? "open": null} >
            <div className="modal-background">
              <div className="modal">
                <div className="vimeo-wrapper">
                  <iframe title="trailer" src={vimeo} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen />
                </div>
              </div>
              <div className="modal__close icon-cross" onClick={this.toggleTrailer}></div>
            </div>
          </div>:
          null
        }
        </div>
      </Layout>
    )
  }
}

export default Home


// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: {eq: 3313}) {
      acf {
        new_titles_header_text
        featured_products {
          featured_product {
            post_name
            post_title
            acf {
              product_poster_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 280) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  publicURL
                }
                source_url
                
              }
              product_new_badge
              product_director
            }
          }
        }
        featured_titles_header_text
        featured_titles {
          featured_type
          featured_title
          featured_description
          featured_url
          featured_url_window
          featured_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          
        }
      }
    }
  }
`
// featured_product_trailer {
//   post_title
//   post_name
//   acf {
//     product_vimeo_id
//   }
// }
