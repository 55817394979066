import React, { useEffect } from 'react';
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroCarousel  = ({ title, featuredTitles, showTrailer }) => {
    var settings = {
        centerMode: true,
        waitForAnimate: true,
        centerPadding: '20%',
        slidesToShow: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  centerPadding: '20%',
                  slidesToShow: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                  centerPadding: '0%',
                  slidesToShow: 1
                }
            },
            {
                breakpoint: 640,
                settings: {
                centerPadding: '0%',
                  slidesToShow: 1
                }
            }
        ]
    };
    function linkPage(event, url, newWin) {
        event.stopPropagation();
        var senderElement = event.target;

        if (senderElement.nodeName == "A") {
            
        } else {
            if (newWin){
                window.open(url, "_blank");
            } else {
                window.location.href = url
            }
        }
        
    }
    
    return (
        <section className="section carousel carousel--hero">
            <h3 className="section__header"><span>{title}</span></h3>
            <div className="carousel--wrapper">
            <Slider {...settings} >
                {featuredTitles.map(( title, i ) => {
                    return(
                    <div key={"film" + i} className={title['featured_type'] === "Article" ? "tile article": "tile video"} onClick={(event) => {title['featured_type'] === "Article" ? linkPage(event, title['featured_url'], title['featured_url_window']) : showTrailer(title['featured_product_trailer'].acf.product_vimeo_id);}}>
                        <div className="tile__media">
                            <Img className="tile__img" src={title['featured_image'].localFile.childImageSharp.fluid.src} fluid={title['featured_image'].localFile.childImageSharp.fluid} />
                        </div>
                        <div className="tile__details">
                            {title['featured_type'] === "Article"?
                                <>
                                <div className="tile__title">{title['featured_type'] === "Article" ? title['featured_title'] : title['featured_product_trailer'].post_title} </div> 
                                <div className="tile__description" dangerouslySetInnerHTML={{ __html: title['featured_description'] }} /></>: 
                                <a href={"./" + title['featured_product_trailer']['post_name']}><div className="tile__title">{title['featured_type'] === "Article" ? title['featured_title'] : title['featured_product_trailer'].post_title}</div></a> }
                        </div> 
                    </div> )
                })}
            </Slider>
            </div>
            
        </section>
    )
}

HeroCarousel.defaultProps = {
    featuredTitles: [],
    title: ""
}
HeroCarousel.propTypes = {
    featuredTitles: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string
}
export default HeroCarousel;

