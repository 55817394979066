import React from 'react'
import PropTypes from "prop-types"
import Slider from "react-slick";
import Img from "gatsby-image"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturedFilms = ({title, films}) => {
    var settings = {
        slidesToShow: 5,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3
                }
            },
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3
                }
            }
        ]
      };
      const linkPage = (url) => {
          window.location.href = url;
      };
    return(
        <section className="section carousel carousel--thumbs">
            <h3 className="section__header carousel--header" ><span onClick={() => linkPage('./catalog')}>{title}</span></h3>
            <Slider {...settings}>

                {films.map(( film, i  ) => (
                    <div onClick={() => linkPage("/" + film['featured_product'].post_name)} key={film['featured_product'].post_name}>
                    <div className="tile">
                        <div className={film['featured_product'].acf['product_new_badge']? "tile__media tile__new": "tile__media"}>
                            {film.featured_product.acf.product_poster_image.localFile.childImageSharp? <Img src={film.featured_product.acf.product_poster_image.localFile.childImageSharp.fluid.src} fluid={film.featured_product.acf.product_poster_image.localFile.childImageSharp.fluid} />: <img src={film.featured_product.acf.product_poster_image.localFile.publicURL} alt={"poster_" + i}/>}

                            {/* <img className="tile__img" src={film['featured_product'].acf['product_poster_image']['source_url']} alt=""  /> */}
                        </div>
                        <div className="tile__details">
                            <div className="tile__title">
                                {film['featured_product'].post_title}
                            </div>
                            <div className="tile__director">
                                Directed by {film['featured_product'].acf['product_director'].toLowerCase()}
                            </div>
                        </div>
                    </div>    
                    </div>                    
                ))}
            </Slider>
        </section>
    )
}

FeaturedFilms.defaultProps = {
    title: "",
    films: []
}
FeaturedFilms.propTypes = {
    title: PropTypes.string,
    films: PropTypes.arrayOf(PropTypes.object)
}
export default FeaturedFilms;

